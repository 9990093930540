import Capabilities from "../Capabilities/Capabilities";
import Documents from "../Documents/Documents";
import Instruction from "../Instruction/Instruction";
import MainBanner from "../MainBanner/MainBanner";

import styles from "./Main.module.css";

const Main = () => (
  <main className={styles.main}>
    <MainBanner />
    <Capabilities />
    <Instruction />
    <Documents />
  </main>
);

export default Main;
