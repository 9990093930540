import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function Register() {
  return (
    <div
      style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
    >
      <Header />
      <div style={{ display: "flex", flexGrow: 1 }}></div>
      <Footer />
    </div>
  );
}

export default Register;
