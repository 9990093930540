import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />
        <Route path="form" element={<Register />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}

export default App;
