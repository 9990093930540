import logo from "../../../assets/images/logo.png";

import styles from "./Logo.module.css";

const Logo = () => (
  <div className={styles.logo}>
    <img
      src={logo}
      alt="Мобильное приложение «Делу время»"
      width="130"
      height="33"
    />
  </div>
);

export default Logo;
