import Logo from "./Logo/Logo";
import Navigation from "./Navigation/Navigation";
import Container from "../Container/Container";

import styles from "./Header.module.css";

const Header = () => (
  <header className={styles.header}>
    <Container>
      <Logo />
      <Navigation />
    </Container>
  </header>
);

export default Header;
