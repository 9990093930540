import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// import useWindowSize from "../../hooks/useWindowSize";
import step1 from "../../assets/images/reg_step1.png";
import step2 from "../../assets/images/reg_step2.png";
import step3 from "../../assets/images/reg_step3.png";
import step4 from "../../assets/images/reg_step4.png";

import "swiper/css";
import "swiper/css/pagination";

import styles from "./InstructionSlider.module.css";

function InstructionSlider() {
  // const { width } = useWindowSize();
  return (
    <>
      <Swiper
        spaceBetween={10}
        // slidesPerView={width && width > 375 ? 4 : 1}
        breakpoints={{
          376: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: 3,
          },
          1201: {
            slidesPerView: 4,
          },
        }}
        pagination={{
          clickable: true,
          el: ".swiper-custom-pagination-instruction",
        }}
        modules={[Pagination]}
      >
        <SwiperSlide className="swiper-slide">
          <div className={styles.instruction}>
            <div className={styles["instruction-img-wrap"]}>
              <img
                src={step1}
                alt="Регистрация шаг 1"
                width="138"
                height="138"
              />
            </div>
            <div className={styles["instruction-wrap"]}>
              <h3>1</h3>
              <p>
                Наведите камеру телефона на QR-код, чтобы скачать приложение
                «Делу время»
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className={styles.instruction}>
            <div className={styles["instruction-img-wrap"]}>
              <img
                src={step2}
                alt="Регистрация шаг 2"
                width="126"
                height="80"
              />
            </div>
            <div className={styles["instruction-wrap"]}>
              <h3>2</h3>
              <p>Введите логин и пароль для входа</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className={styles.instruction}>
            <div className={styles["instruction-img-wrap"]}>
              <img
                src={step3}
                alt="Регистрация шаг 3"
                width="142"
                height="96"
              />
            </div>
            <div className={styles["instruction-wrap"]}>
              <h3>3</h3>
              <p>Введите код из СМС, чтобы подтвердить регистрацию</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className={styles.instruction}>
            <div className={styles["instruction-img-wrap"]}>
              <img
                src={step4}
                alt="Регистрация шаг 4"
                width="142"
                height="98"
              />
            </div>
            <div className={styles["instruction-wrap"]}>
              <h3>4</h3>
              <p>Выберите компанию</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-custom-pagination-instruction" />
    </>
  );
}

export default InstructionSlider;
