import logo from "../../assets/images/logo_dark.png";

import styles from "./Footer.module.css";

const Footer = () => (
  <section id="footer" className={styles.footer}>
    <div className={styles.container}>
      <img
        src={logo}
        alt="Мобильное приложение «Делу время»"
        width="130"
        height="33"
      />
      <span>ООО “ЕГАР Сервис”</span>
    </div>
  </section>
);

export default Footer;
