import { FC } from "react";
import styles from "./Container.module.css";

type Props = {
  children?: React.ReactNode;
};

const Container: FC<Props> = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

export default Container;
