import styles from "./Navigation.module.css";

const Navigation = () => (
  <nav className={styles.navigation}>
    <a href="#capabilities">Возможности</a>
    <a href="#instruction">Регистрация</a>
    <a href="#documents">Документы</a>
  </nav>
);

export default Navigation;
