import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass from "swiper/types/swiper-class";
import { Pagination, Thumbs } from "swiper";
import { ReactComponent as Mortgage } from "../../assets/images/icons/mortgage.svg";
import { ReactComponent as Signature } from "../../assets/images/icons/signature.svg";
import { ReactComponent as MarkedEvent } from "../../assets/images/icons/marked_event.svg";

import "swiper/css";
import "swiper/css/thumbs";
import "swiper/css/pagination";

import styles from "./CapabilitiesSlider.module.css";

function CapabilitiesSlider() {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();

  return (
    <>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={3}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[Thumbs]}
        className={styles["capabilities-slider"]}
      >
        <SwiperSlide className="swiper-slide">
          <div className={styles["swiper-slide__item"]}>
            <Mortgage className={styles["swiper-slide__item-img"]} />
            <p className={styles["swiper-slide__item-title"]}>
              Управляйте ключами электронной подписи
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className={styles["swiper-slide__item"]}>
            <Signature className={styles["swiper-slide__item-img"]} />
            <p className={styles["swiper-slide__item-title"]}>
              Подписывайте документы
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className={styles["swiper-slide__item"]}>
            <MarkedEvent className={styles["swiper-slide__item-img"]} />
            <p className={styles["swiper-slide__item-title"]}>
              Будьте в курсе событий
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
      <Swiper
        spaceBetween={10}
        pagination={{
          clickable: true,
          el: ".swiper-custom-pagination",
        }}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[Thumbs, Pagination]}
        className={styles["slider"]}
      >
        <SwiperSlide className="swiper-slide">
          <div className={styles["slider__content"]}>
            <div className={styles["slider__content-wrapper"]}>
              <h5>Управляйте ключами электронной подписи</h5>
              <p>Выпускайте ключи и входите по QR-коду</p>
            </div>
            <div
              className={`${styles["slider__content-img"]} ${styles.mortgage}`}
            ></div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className={styles["slider__content"]}>
            <div className={styles["slider__content-wrapper"]}>
              <h5>Подписывайте документы</h5>
              <p>Ведите документооборот компании онлайн</p>
            </div>
            <div
              className={`${styles["slider__content-img"]} ${styles.signature}`}
            ></div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className={styles["slider__content"]}>
            <div className={styles["slider__content-wrapper"]}>
              <h5>Будьте в курсе событий</h5>
              <p>Отслеживайте статус документов</p>
            </div>
            <div
              className={`${styles["slider__content-img"]} ${styles.marked}`}
            ></div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-custom-pagination" />
    </>
  );
}

export default CapabilitiesSlider;
