import { ReactComponent as Doc } from "../../assets/images/icons/doc.svg";
import pdf from "../../assets/files/business-first.pdf";

import styles from "./Documents.module.css";

const Documents = () => (
  <section id="documents" className={styles.documents}>
    <div className={styles.container}>
      <h2>Документы</h2>
      <div className={styles["documents_loader"]}>
        <Doc />
        <div className={styles["documents_wrapper"]}>
          <a href={pdf} download>
            Правила безопасности при работе с онлайн-сервисами
          </a>
          <span>pdf, 4.3 Мб</span>
        </div>
      </div>
    </div>
  </section>
);

export default Documents;
