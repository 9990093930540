import CapabilitiesSlider from "../CapabilitiesSlider/CapabilitiesSlider";

import styles from "./Capabilities.module.css";

const Capabilities = () => (
  <section id="capabilities" className={styles.capabilities}>
    <div className={styles.container}>
      <h2>Все у вас под рукой</h2>
      <p>Экономьте время и всегда будьте в курсе событий вашей компании</p>
      <CapabilitiesSlider />
    </div>
  </section>
);

export default Capabilities;
