import { ReactComponent as QR } from "../../assets/images/qr.svg";
import { ReactComponent as AppStore } from "../../assets/images/AppStore.svg";

import styles from "./MainBanner.module.css";
import Container from "../Container/Container";

const MainBanner = () => (
	<section className={styles["main-banner"]}>
		<Container>
			<div className={styles["main-banner-title"]}>
				<h1>Мобильное приложение «Делу время»</h1>
				<p>Все ваши операции на одном экране</p>
				<a
					href="https://apps.apple.com/ru/app/делу-время/id6449224169"
					target="_blank"
					rel="noreferrer"
					className={styles.download}
				>
					Скачать
				</a>
				<div className={styles["main-banner-qr"]}>
					<QR />
					<div className={styles["main-banner-qr-wrapper"]}>
						<p>Наведите камеру телефона на QR-код, чтобы скачать приложение</p>
						<a
							href="https://apps.apple.com/ru/app/делу-время/id6449224169"
							target="_blank"
							rel="noreferrer"
						>
							<AppStore />
						</a>
					</div>
				</div>
			</div>
			<div className={styles["main-banner-img"]}></div>
		</Container>
	</section>
);

export default MainBanner;
