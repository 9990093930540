import InstructionSlider from "../InstructionSlider/InstructionSlider";

import styles from "./Instruction.module.css";

const Instruction = () => (
  <section id="instruction" className={styles.instruction}>
    <div className={styles.container}>
      <h2>Зарегистрироваться просто</h2>
      <div className={styles["capabilitie__slider"]}>
        <InstructionSlider />
      </div>
      <p className={styles["instruction__note"]}>
        Если вы уже зарегистрированы, войдите под действующими логином и
        паролем.
      </p>
    </div>
  </section>
);

export default Instruction;
